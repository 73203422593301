<template>
	<!--v-loading="loadingFlag"-->
  <div class="vg_wrapper" >
    <div class="vd_edhea">
     <editHeader
	     :statusShow="false"
        :isShow="isShow"
        :btn="btn"
        :strForm="factForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('factForm')"/>
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <!-- <el-button type="primary" size="small" class="vd_exports" @click="buttonRefresh()" v-if="isShow">打印预览</el-button> -->
    </div>
	  <el-form ref="factForm" :disabled="isShow" :rules="rules" :model="factForm" label-width="120px" size="mini">
		  <el-row class="vg_mb_5">
			  <el-col :md="8">
				  <el-form-item label="成品采购号" prop="modr_no">
					  <el-input v-model="factForm.modr_no" clearable maxlength="30"  show-word-limit placeholder="请填写成品采购号"/>
				  </el-form-item>
			  </el-col>
			  <el-col :md="8">
				  <el-form-item label="采购方名称" prop="cptt_name">
					  <el-select v-model="factForm.cptt_name" placeholder="请选择采购方" clearable>
						  <el-option
								  v-for="item in cpptList"
								  :key="item.cptt_id"
								  :label="item.cptt_name"
								  :value="item.cptt_name">
						  </el-option>
					  </el-select>
				  </el-form-item>
			  </el-col>
			  <el-col :md="8">
				  <el-form-item label="采购日期" prop="fact_date">
					  <el-date-picker
							  v-model="factForm.fact_date"
							  type="date"
							  placeholder="选择日期">
					  </el-date-picker>
				  </el-form-item>
			  </el-col>
		  </el-row>
		  <el-row class="vg_mb_5">
			  <el-col :md="24" class="vg_mb_5">
				  <el-form-item label="收货信息" prop="fact_addr">
					  <el-input v-model="factForm.fact_addr" type="textarea" :rows="3" clearable placeholder="请填写收货信息"/>
				  </el-form-item>
			  </el-col>
		  </el-row>
		  <el-row>
			  <el-col :md="24" class="vg_mb_5">
				  <el-form-item label="订单备注" prop="fact_remark">
					  <el-input type="textarea" :rows="3" v-model="factForm.fact_remark" clearable show-word-limit placeholder="请填写备注"/>
				  </el-form-item>
			  </el-col>
		  </el-row>
		  <div class="vg_button">
			  <el-button type="primary" v-if="userInfo.acct_id !== 1" plain size="small" @click="addNullRow()">新增</el-button>
			  <el-button type="danger" v-if="userInfo.acct_id !== 1" plain size="small" @click="delBank()">删除</el-button>
		  </div>
		  <el-row class="vd_mrt vg_mb_5">
			  <FactAddProd :isShow="false" @handleSelectionChange="handleSelect" :factForm="factForm"></FactAddProd>
		  </el-row>
		  <el-row class="vg_mt_16 vd_bortop">
			  <el-col>
				  <inputUser :isCookie="true" ref="userData"></inputUser>
			  </el-col>
		  </el-row>
	  </el-form>
  </div>
</template>

<script>
import {get, post} from "@api/request";
import {cpttAPI} from "@api/modules/comptitle";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
import FactAddProd from "@/views/PurcManagement/FactManage/TabChild/Compont/TabPanel/FactAddProd"
import {factAPI} from "@api/modules/fact";

export default {
	name: "smplAddMain",
	components: {
		editHeader,
		inputUser,
		FactAddProd,
	},
	data() {
		return {
			rules: {
				cptt_name: [{required: true, trigger: 'change', message: ' '},],
				modr_no: [{required: true, trigger: 'change', message: ' '},],
				supp_name: [{required: true, trigger: 'change', message: ' '},],
				fact_date: [{required: true, trigger: 'blur', message: ' '},],
				fact_addr: [{required: true, trigger: 'blur', message: ' '},],
			},
			isShow: true,
			factForm: {},
			removePodrProdLi: [],
			factFormLi: [],
			btn: {},
			stffForm: {},
			loadingFlag: true,
			factAcctCope: [],
			notice: false,
			factProdLi: [],
			cpptList: [],
			title: '产品信息表',
			specialDataFlag: false,
			custList: [],
			userInfo:{}
		}
	},
	watch: {
		// 下拉选择框选择补全id
		'factForm.cust_abbr'(newValue, oldValue) {
			if (newValue) {
				setTimeout(() =>{
					let find = this.custList.find(x => x.cust_abbr === newValue);
					this.factForm.cust_inspstand = find.cust_inspstand
					this.factForm.cust_id = find.cust_id
				},500)
			}
		},
		'factForm.cptt_name'(newValue, oldValue) {
			if (newValue) {
				setTimeout(() =>{
					let find = this.cpptList.find(x => x.cptt_name === newValue);
					this.factForm.cptt_id = find.cptt_id
				},500)
			}
		},
	},
	created() {
		this.userInfo = this.$cookies.get('userInfo')
		this.initData()
		this.log = false;
	},
	methods: {
		initData() {
			this.loadingFlag = true
			this.getList()
			this.getCppt()
			if (this.$cookies.get('userInfo').acct_id === 1) {
				this.specialDataFlag = true
			}
		},
		//提交表单
		submit(formName) {
			this.$confirm('确认提交?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.saveInfo()
					} else {
						console.log('error submit!');
						return false;
					}
				});
			}).catch(() => {
			})
		},
		// 保存
		saveInfo() {
			let factForm = {};
			factForm = Object.assign(factForm, this.factForm);
			factForm.fact_date = (new Date(this.factForm.fact_date)).getTime() / 1000;
			this.factAcctCope = this.factAcctCope.filter(item => factForm.fact_mtrb_list.every(item1 => item.fact_mtrb_id !== item1.fact_mtrb_id))
			for (let i = 0; i < this.factAcctCope.length; i++) {
				this.factAcctCope[i].destroy_flag = 1
			}
			console.warn(this.factAcctCope);
			factForm.fact_mtrb_list = factForm.fact_mtrb_list.concat(this.factAcctCope);
			post(factAPI.editFacts, factForm)
					.then(({data}) => {
						if (data.code === 0) {
							this.$message({
								type: 'success',
								message: '保存成功'
							})
							this.isShow = true;
							this.$emit('isShow', this.isShow)
							this.initData()
						}
					})
		},
		// 刷新按钮
		buttonRefresh() {
			this.initData()
		},
		//撤销编辑
		closeEdit() {
			this.$confirm('是否撤销编辑?', '提示', {
				confirmButtonText: '是',
				cancelButtonText: '否',
				type: 'warning'
			}).then(() => {
				this.initData()
				this.isShow = true
				this.$emit('isShow', this.isShow)
				this.$message({
					type: 'info',
					message: '已撤销编辑!'
				});
			}).catch(() => {
			})
		},
		//启用编辑
		openEdit() {
			this.isShow = false;
			this.$emit('isShow', this.isShow)
		},
		//删除选择的内容
		handleSelect(val) {
			this.removePodrProdLi = val;
		},
		// 删除产品信息按钮
		delBank(){
			if(this.factForm.fact_mtrb_list.length <= 0){
				return this.$message.warning('至少选择一条数据')
			}
			// 删除选中数据
			let set = new Set(this.factForm.fact_mtrb_list);
			this.removePodrProdLi.forEach((item) => set.delete(item))
			this.factForm.fact_mtrb_list = Array.from(set);
			
		},
		addNullRow() {
			this.factForm.fact_mtrb_list.push({
				cust_artno: null,
				mtrl_id: null,
				mtrl_name: null,
				mtrl_no: null,
				mtrl_spec: null,
				mtrl_type: null,
				mtrl_comp: null,
				mtrl_color: null,
				mtrl_unit: null,
				mtrl_width: null,
				mtrl_thick: null,
				imge_url: null,
			})
		},
		// 打开导入产品信息小窗口
		addRow() {
			this.notice = true;
		},
		// 导入产品信息小窗口确定按钮
		confirmIn(val) {
			this.factProdLi = val;
			this.notice = false;
			this.factProdLi = this.factProdLi.filter(item => this.factForm.puca_mtrb_list.every(item1 => item.puca_id !== item1.puca_id));
			this.factForm.puca_mtrb_list = this.factForm.puca_mtrb_list.concat(this.factProdLi);
			this.$refs.PodrAddEditIn.clear()
		},
		// 取消选择
		cancelPodrIn() {
			this.notice = false
		},
		// 获取合作单位信息
		getCppt(){
			get(cpttAPI.getAllCpttsV1, {}).then(res => {
				if (res.data.code === 0) {
					this.cpptList = res.data.data;
				}
			})
		},
		// 获取信息步骤
		getList() {
			get(factAPI.get_fact_by_id, {fact_id: this.$route.query.form_id}).then(res => {
					if (res.data.code === 0) {
						// 权限按钮
						this.btn = res.data.data.btn;
						this.factForm = res.data.data.form;
						this.getInputUser()
						this.factAcctCope = JSON.parse(JSON.stringify(this.factForm.fact_mtrb_list))
						this.factForm.fact_date = this.factForm.fact_date ? this.factForm.fact_date * 1000 : null;
						setTimeout(() => {
							this.loadingFlag = false
						}, 500);
					}
				});
		},
		//获取录入人
		getInputUser() {
			this.stffForm.cptt_id = this.factForm.cptt_id;
			this.stffForm.user_id = this.factForm.user_id;
			this.stffForm.dept_id = this.factForm.dept_id;
			this.stffForm.stff_id = this.factForm.stff_id;
			this.stffForm.cptt_abbr = this.factForm.cptt_abbr;
			this.stffForm.dept_name = this.factForm.dept_name;
			this.stffForm.dept_team_id = this.factForm.dept_team_id;
			this.stffForm.dept_team_name = this.factForm.dept_team_name;
			this.stffForm.stff_name = this.factForm.stff_name;
		},
		//表单重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
	}
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_mrt{
  margin-top: 20px;
}
.vd_eltab {
  margin-top: 10px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
.vd_exports{
  position: absolute;
  top: 0;
  left: 120px;
}
.vd_draw{
  padding: 20% 10%;
}
.vd_export1{
  position: absolute;
  top: 0;
  left: 120px;
}
</style>